@font-face {
  font-family: "Roboto";
  src: url("/Roboto/Roboto-Regular.ttf");
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5 {
  font-family: Roboto;
}

a,
a:hover {
  color: inherit;
}

*:focus {
  outline: none;
}

.diskon {
  font-size: 22px;
  font-weight: bold;
}

.total-akhir {
  font-size: 22px;
  font-weight: bold;
}

.panel-payment {
  border: 1px solid #1BA0E2;
  border-radius: 10px;
  padding: 10px;
}

.selected-payment {
  border: 1px solid #1BA0E2;
  padding: 10px;
  border-radius: 10px;
}

.no-selected-payment {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.teks-besar {
  text-transform: uppercase;
  text-align: center;
  font-size: 32px;
  margin-bottom: 16px;
  color: #21409a;
  width: 100%;
}

.detail__date {
  color: #999999;
  font-size: 12px;
  margin-bottom: 12px;
}

.ant-steps-item-icon {
  background: #8FC742 !important;
}

.login-daftar-link {
  color: #8FC742 !important;
}

.ant-steps-icon {
  color: #ffffff !important;
}

.ant-steps-finish-icon {
  background: #8FC742;
  color: #DEA806;
}

.btn-primary {
  background: #138AFF !important;
  color: #ffffff !important;
  text-transform: initial;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  background: #8FC742;
  color: #000000;
}

.splash {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.title-splash {
  font-size: 24px;
  font-weight: 700;
  color: #1897FF;
  margin-top: 50px;
}

.eclipse {
  position: absolute;
  width: 147px;
  height: 147px;
  left: calc(100% - 50% - (147px / 2));
  top: -114px;
  background: #01ADEE;
  border-radius: 50%;
}

.eclipse-bawah {
  position: absolute;
  width: 147px;
  height: 147px;
  left: -50px;
  bottom: -50px;
  background: #01ADEE;
  border-radius: 50%;
}

.form {
  border-radius: 10px;
  /*background: rgb(1,173,238, 0.3);*/
  background: rgb(255, 255, 255, 0.7);
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  /*height: 320px;*/
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
}

.form-pendaftaran {
  width: 80%;
  /*height: 320px;*/
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  margin: 0px auto;
}

/*
p {
    color: rgb(204, 204, 204);
    text-align: center;
    margin-top: 16px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}
*/
.logo-login {
  text-align: center;
  cursor: pointer;

}

.logo-login img {
  /*width: 40px;*/
  width: 70%;
}

.logo-login span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;

  font-weight: 700;
  color: #138AFF;
}

.ant-spin-container,
.ant-spin-nested-loading {
  height: 100%;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.ant-modal-title {
  text-align: center;
  font-weight: bold;
  color: #000;
}

.blur-qr {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
}

.root-login {
  background: url('/bg-login.jpg') no-repeat !important;
  background-size: 100% 100vh !important;
  height: 100vh !important;
  width: 100% !important;
}

.is-active {
  color: yellow !Important;
  font-weight: bold !Important;
}

.ant-menu-item>a {
  color: #fff !Important;
}

.ant-menu-item-selected>a {
  color: yellow !Important;
  font-weight: bold !Important;
}

.ant-menu-item-selected {
  border-bottom: 2px solid yellow !Important;
}

.ant-menu-sub>.ant-menu-item-selected>a {
  color: #01ACED !Important;
  font-weight: bold !Important;
}

.ant-menu-sub>.ant-menu-item>a {
  color: rgba(0, 0, 0, 0.65) !Important;
}

.ant-menu-sub>.ant-menu-item-selected {
  border-bottom: none !Important;
}


.ant-popover {
  z-index: 9004 !Important;
}

.awssld__bullets {
  bottom: 50px !Important;
  /*z-index: 9001 !important;*/
}

.awssld__bullets>button {
  width: 5px !Important;
  height: 5px !Important;
  /*background: #000 !Important;*/
}

/*
.awssld__content{
  /*height: 125% !Important;
}*/

.nama-produk {
  color: #1BA0E2;
  font-size: 18px;
  font-weight: bold;
}

.panel-detail {
  /* margin-top: 30px; */
  padding-left: 20px;
  padding-right: 20px;
}

.panel-video {
  text-align: center;
}

.label-video {
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}

.rating {
  color: #FFA726;
  font-size: 22px;
  margin: 0px auto;
}

.rating span {
  font-size: 22px !Important;
}

.avg-rating {
  font-weight: bold;
  /*padding-left: 10px;*/
  font-size: 14px;
}

.jml-rating {
  /*padding-left: 10px;*/
  font-size: 14px;
  font-weight: bold;
}

.panel-rating {
  border-right: 1px solid #ccc;
}
.panel-rating > div:first-child{
  justify-content: center !important;
  align-items: center;
}

.panel-komentar {
  /*text-align: center;*/
  border-right: 1px solid #ccc;
}

.komentar-logo {
  color: #1BA0E2;
  font-size: 22px;
}

.panel-ul-kom-suk {
  /* padding-top: 30px; */
  margin-top: 10px;
}

.panel-vaksin {
  padding-top: 20px;
  font-size: 12px;
}

.btn-beli {
  /* position: absolute !important;
  bottom: 0; */
  /* position: fixed; */
  margin-top: 20px;
  width: 100%;
  background: #FFA726;
  border: none;
  border-radius: 10px;
  padding: 10px;
  /* margin-bottom: 10px; */
  color: #fff;
  font-size: 14px;
  box-shadow: 3px 3px 3px 3px #ccc;
  cursor: pointer;
}

.label-produk {
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-top: 10px;
  background: #1BA0E2;
  padding: 5px;
  border-radius: 10px;
}

.produk-list {
  border-bottom: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
}

del {
  color: red;
  margin-right: 5px;
}

.sub-total {
  font-weight: bold;
}

.btn-kurangi {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid #ccc;
  border-right: none;
  padding: 10px;
  background: #1BA0E2;
  color: #fff;
  cursor: pointer;
  width: 100%;
}

.btn-tambah {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid #ccc;
  border-left: none;
  padding: 10px;
  background: #1BA0E2;
  color: #fff;
  cursor: pointer;
  width: 100%;
}

.qty {
  border-radius: 0px;
  width: 100%;
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
}

.total {
  font-weight: bold;
}

.komen-avatar {
  background: #1BA0E2;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  padding: 10px;
  width: 40px;
}

.komen-rating {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
}

.komen-tgl {
  font-size: 9px;
  font-style: italic;
}

.komen-nama {
  font-weight: bold;
}

.komen-tanggal {
  font-style: italic;
  font-size: 12px;
}

.komen-produk {
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 5px;
  display: inline-block;
  font-size: 12px;
  color: #;
}

.komen-isi {
  clear: both;
}

.komen-garis {
  border-bottom: 1px solid #ccc;
  clear: both;
  margin-bottom: 5px;
}

.btn-komen-selengkapnya {
  color: #1BA0E2;
  border: none;
  background: none;
  padding: 5px;
  cursor: pointer;
}

.judul {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.label-petunjuk {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.ant-tabs-tab,
.ant-tabs-tab:hover {
  background: #1BA0E2 !Important;
  color: #fff !Important;
}

/*.ant-tabs-tab:hover{
  background: #1BA0E2 !Important;
  color: #fff !important;
}*/
.ant-tabs-tab-active {
  background: #ccc !Important;
  color: #1BA0E2 !Important;
}

.ant-tabs-tab-active:hover {
  background: #ccc !Important;
  color: #1BA0E2 !Important;
}

.nama-app {
  text-align: center;
}







.garis-panel {
  background: #ccc;
  width: 30%;
  height: 5px;
  border-radius: 20px;
  margin: 10px auto;
  top: 20px;
  position: relative;
}

.awssld__startUp {
  background: linear-gradient(to right, #6a71fc, #1dc6f4) !Important;
}

.arrow-submenu {
  position: absolute;
  right: -5px;
}

.logo-menu {
  /*background: #fff;
  margin-bottom: 5px;
  height: 40px;*/
  padding: 0px;
  padding-left: 5px;
  margin-left: 5px;
}

.logo-menu img {
  height: 60px;
}

.menu-akun {
  /*float: right;*/
}

.menu-cari {
  float: right;
  /*display: flex !Important;
  align-items: center !Important;
  justify-content: center*/
  ;

}

.menu-cari .cari-atas {
  margin-top: 5px;
  width: 300px;
}

.label-slider {
  /*background: rgba(0, 0, 0, 0.2);*/
  /*background: linear-gradient(to right, #6a71fc , #1dc6f4) !Important;*/
  /*background: linear-gradient(to right, rgb(106, 113, 252, 0.3) , rgb(29, 198, 244, 0.3)) !Important;*/
  /*z-index: 1;
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: bold;
  color:  #fff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;*/
  /*position: absolute;
  bottom:  30%;*/
  /*padding-top: 10px;*/
  /*position: absolute;
  display: flex;
  align-items: center;*/


  /*width: 200px;
  height: 0px;
  border-top: 150px solid transparent;
  border-right: 250px solid rgba(0, 0, 0, 0.2);
  border-bottom: 150px solid transparent;
  position: absolute;
  right: 0*/


}

.card-panel {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #fff;
  min-height: 100vh;
  z-index: 9000 !important;
  position: relative;
  /*top:  -75px;*/
  /*padding-left: 10px;
  padding-right: 10px;*/
}

.konten-panel {
  margin-top: 30px;
}

.heading {
  padding-bottom: 5px;
}

.heading>.kiri {
  padding-left: 0px;
  font-size: 18px;
  font-weight: bold;
  color: #4B4B4B;
}

.lokasi {
  font-size: 11px;
  text-transform: lowercase;
  /*background: #474747;*/
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  border-radius: 5px;
  background: linear-gradient(to right, #474747, #595757) !Important;
}

.heading>.kanan {
  font-weight: bold;
  padding-right: 0px;
  font-size: 14px;
  color: #4B4B4B;
  text-align: right;
  color: #1dc6f4;
}

.item-produk {
  /*padding-right: 10px;
  padding-bottom: 10px;*/
  /*padding: 8px 0;*/
  padding: 10px 0;
}

.ant-select-dropdown {
  z-index: 10000 !Important;
}

.panel-item-produk {
  border-radius: 10px;
  /*border:  1px solid #ccc;*/
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
  background: #fff;
  /*border:  1px solid red;*/
}

.panel-gambar-produk {
  border-radius: 10px;
  height: 160px;
  /*border:  1px solid green;*/
}

.panel-gambar-produk img,
.panel-gambar-produk>.ant-skeleton,
.panel-gambar-produk>.ant-skeleton>.ant-skeleton-content,
.panel-gambar-produk>.ant-skeleton>.ant-skeleton-content>h3,
.gbr-produk-grid {
  width: 100% !Important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
  height: 160px !Important;
  /*border:  1px solid blue;*/
}

.item-produk-nama {
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-produk-rating {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.item-produk-rating .ant-col {
  display: flex;
  align-items: center;
  font-size: 10px;
}

.item-produk-rating>.ant-col>img {
  margin-right: 5px;
}

.item-produk-rate {
  justify-content: center;

}

.item-produk-dilihat {
  justify-content: center;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.item-produk-disukai {
  justify-content: center;
}

.item-produk-jenis {
  margin-top: 5px;
  margin-left: 5px;
  display: flex;
  align-items: center;

}

.slick-slide {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.papan-info {
  padding: 10px;
}

.btn-selengkapnya {
  background: linear-gradient(to right, #6a71fc, #1dc6f4) !Important;
  border: none;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  cursor: pointer;
}

.btn-selengkapnya2 {
  background: #ccc;
  border: none;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.center {
  text-align: center;
}

.btn-jenis-produk {
  background: linear-gradient(to right, #6a71fc, #1dc6f4) !Important;
  border: none;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  font-size: 10px;
  margin-right: 5px;
}

.blok-gap-ganjil {
  background: #fff;
  margin-bottom: 20px;
}

.blok-gap-netral {
  background: #fff;
  margin-bottom: 20px;
}

.blok-gap-genap {
  background: linear-gradient(to right, #6a71fc, #1dc6f4) !Important;
  color: #fff !Important;
}

.blok-gap-genap .heading>.kiri,
.blok-gap-genap .heading>.kanan {
  color: #fff !Important;
}

.menu-utama {
  /*background: linear-gradient(to right, rgb(106, 113, 252, 1) , rgb(29, 198, 244, 1)) !Important;*/
  /*background: linear-gradient(to right, rgb(0, 0, 0, 0.5) , rgb(0, 0, 0, 0.5)) !Important;*/
  color: #000;
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: 100px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.19);
  /*width: 90%;

  border-radius: 10px;
  top:  5px;
  left: 0;
  right: 0;
    
    width: 95%;
    margin: auto; */

  /*height: 70px;*/
  /*display: flex;
  align-items: center;*/
}

.menu-utama-mobile {
  background: linear-gradient(to right, rgb(106, 113, 252, 1), rgb(29, 198, 244, 1)) !Important;
  /*background: linear-gradient(to right, rgb(255, 255, 255, 0.5) , rgb(255, 255, 255, 0.5)) !Important;*/
  /*background: linear-gradient(to right, rgb(0, 0, 0, 0.5) , rgb(0, 0, 0, 0.5)) !Important;*/
  color: #FFF;
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.menu-utama-mobile img {
  height: 30px;
}

.menu-utama-mobile button {
  margin-bottom: 0px !Important;
}

.menu-utama-mobile .ant-col {
  padding: 5px;
}

.cari-atas {
  width: 200px;
}

.main-home {
  overflow-x: hidden;
  overflow-y: hidden;
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:hover:after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow:hover:after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow:hover:after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow:hover:after {
  color: #ffffff !important;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1)) !important;
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
}

.tbl-text {
  border: none !Important;
  background: transparent !Important;
}

.btn-netral {
  border: none;
  background: transparent;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

.copyright {
  font-size: 12px;
  font-weight: bold;
}

.footernya {
  margin-top: 30px;
  /*margin-bottom: 20px;*/
  height: 290px;

  /*-webkit-box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.2);*/
  padding: 10px;

  background: #2A2A2A;
  /*position: relative;
  bottom: 0;*/
  position: relative;
  bottom: 0;
  width: 100%;
  clear: both;
}

.single-content {
  margin-top: 70px;
}

.single-content>.heading>.kiri {
  padding-left: 0px;
}

.ant-menu-submenu-popup {
  z-index: 100000 !Important;
}

.panel-info-ticker {
  padding-right: 20px;
}

.info-ticker {
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  /*padding: 10px;*/
  border-radius: 10px;

  margin-top: 120px;
  /*border:  1px solid #ccc;*/
  padding-left: 10px;
  padding-right: 10px;
  /*padding-right: 10px;*/


  height: fit-content;
  /*background: linear-gradient(to right, #fff , #ccc) !Important;*/
}

.info-ticker ul {
  list-style-type: none;
  padding-left: 0px !Important;
}

.post-item {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;

  /*height: 100px;*/
  clear: both;
}

/*.panel-info-ticker{
  border:  1px solid red;
  height: 620px !Important;
}*/
.post-img {
  width: 100%;

}

.post-img,
.post-text {}

.post-text {}

.promo-ticker {
  max-height: 465px;
}

.post-img img {
  width: 100%;
  height: 80px;
  object-fit: cover;
}

.post-meta .btn-selengkapnya {
  float: right;
}

.post-meta h6 {
  font-size: 12px;
  font-weight: bold;
}

.post-meta .tgl {
  line-height: 2px;
  font-size: 10px;
  font-style: italic;
}

.post-meta .isi {
  text-align: justify;
  font-size: 12px;
  margin-bottom: 1px;
}

.not-avaliable-usaha {
  z-index: 10000;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(242, 32, 36, 0.6);
  border-top-right-radius: 10px;
  padding: 10px;
  font-size: 24px;
  color: #fff;
  text-transform: lowercase;
  /*font-weight: bold;*/
}

.not-avaliable-produk {
  background: rgba(242, 32, 36, 1);
  /*border-top-right-radius: 10px;*/
  padding: 10px;
  font-size: 14px;
  color: #fff;
  text-transform: lowercase;
  /*font-weight: bold;*/
  display: inline-block;
}

.info-pin {
  /* border: 1px solid red; */
  padding: 10px;
}

.info-pin img {
  width: 100%;
  border-radius: 10px;
}

.info-pin .caption {
  margin-top: 10px;
}

.info-pin .caption .judul {
  text-align: left;
  margin-bottom: 10px;
}

.info-pin .caption .btn-selengkapnya {
  margin-top: 10px;
}

.info-kanan {

  position: relative;
}

.info-kanan .caption {
  padding-left: 10px;

}

.info-kanan img {
  width: 100%;
  border-radius: 10px;
}

.info-kanan .judul {
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;

}

.info-kanan .isi {}

.info-kanan-row {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.info-kanan .btn-selengkapnya {
  position: absolute;
  right: 0;
  bottom: 0;
}

.tgl {
  font-style: italic;
}

.panel-ruang-tunggu {
  text-align: center;
}

.panel-ruang-tunggu img {
  width: 70%;
  border-radius: 10px;
}

.ruang-tunggu-title {
  font-size: 24px;
  color: #003D7A;
  padding-top: 15px;
  padding-bottom: 15px;
}

.ruang-tunggu-caption {
  font-size: 18px;
  color: #4B4B4B;
}

.awssld__content img {
  object-fit: cover !important;
}

.row-info {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.row-info div {
  text-align: left;
}

.row-info .caption {
  padding-left: 10px;
}

.row-info img {
  width: 100%;
  border-radius: 10px;
}

.row-info .btn-selengkapnya {
  position: absolute;
  bottom: 0;
}

.tiketku-heading {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.tiketku-row {
  position: relative;
}

.tiketku-header {
  position: relative;
  background: rgba(1, 173, 238, 1);
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
}

.tiketku-kode {
  left: 0;
}

.tiketku-status {
  position: absolute;
  right: 0;
  top: 10px;
}

.tiketku-body {
  padding: 10px;
  position: relative;
}

.tiketku-btn-etiket {
  position: absolute;
  right: 10px;
  top: 10%;
  background: rgba(105, 105, 105, 0.2);
  height: 80%;

}

.tiketku-sudah-checkin .icon-checkin {
  background: #87D068;
  font-weight: bold;
  border-radius: 50%;
}

a .tiketku-btn-etiket {
  color: unset;
}

.tiketku-btn-etiket:hover {
  background: #01ADEE;
  color: #fff;
}

.tiketku-btn-etiket .icon {
  font-size: 47px;
}

.titik-dua::after {
  content: ":";
  text-align: right;
  float: right;
  margin-right: 5px;
}

.ant-tag {
  border-radius: 5px;
}

.tiketku-detail {
  font-size: 12px;
  font-weight: bold;
  text-transform: lowercase;
  margin-top: 10px;
}

.petunjuk-pembayaran-row {
  /* border-top: 5px dashed #000; */
  margin-top: 20px;
  clear: both;
  /* padding-top: 20px; */
}

.btn-instruksi-pembayaran {
  border-radius: 10px !important;
  /* margin-top: 10px; */
  /* background: #FFA500;
  border: none; */
}

.ant-tabs-tab {
  border-radius: 10px !important;
}
.slider-beranda img{
  height: 500px;
  border-radius: 10px;
  object-fit: fill !important;
}
.slider-beranda .awssld__bullets button {
  width: 10px !Important;
  height: 10px !Important;
  background: #fff;
  border: 2px solid #01ADEE;
  ;
}

.slider-detail img {
  height: 310px !important;
  object-fit: fill !important;
  /* object-position: center; */
  border-radius: 10px;
}

.slider-detail .awssld__bullets {
  bottom: 20px !Important;
}

.slider-detail .awssld__bullets button {
  width: 10px !Important;
  height: 10px !Important;
  background: #fff;
  border: 2px solid #01ADEE;
  ;
}
.panel-gbr-list{
  margin-bottom: 10px;
}
.kanan-detail{
  position: relative;
}

/*for ipad*/
@media screen and (min-device-width: 768px) and (max-device-width: 1024px)  and (orientation:landscape){
  .slider-detail img {
    height: 240px !important;
    object-fit: fill !important;
  } 
  .slider-beranda img{
    height: 460px;
    border-radius: 10px;
    object-fit: fill !important;
  }
  .slider-beranda .awssld__bullets {
    bottom: 20px !Important;
  } 
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait){
  .slider-detail img {
    height: 180px !important;
    object-fit: fill !important;
  }
  .panel-info-ticker{
    display: none;
  }
  .slider-beranda img{
    height: 460px;
    border-radius: 10px;
    object-fit: fill !important;
  }
  .slider-beranda .awssld__bullets {
    bottom: 20px !Important;
  } 

}


/* For Mobile */
@media screen and (max-width: 540px) {
  .garis-panel-detail {
    height: 20px;
  }

  .panel-gbr-list {
    display: none;
  }

  .slider-detail img{
    border-radius: unset !important;
    object-fit: fill !important;
  }

  .slider-detail .awssld__bullets {
    bottom: 50px !Important;
  }

  .detail-page {
    margin-top: 50px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .titik-dua::after {
    float: unset;
    margin-left: 10px;
  }

  .row-info .btn-selengkapnya {
    position: unset !important;
  }

  .info-kanan .caption {
    padding-left: 0px;

  }

  .info-kanan .btn-selengkapnya {
    position: unset;
  }

  .footer-tengah {
    display: none;
  }

  .footernya {
    font-size: 15px;
    padding-top: 50px;
    color: #fff;
  }

  .footer-header {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .footer-atas {
    border-bottom: 1px solid #fff;
  }

  .footer-bawah {
    text-align: center;
    padding-top: 10px;
  }


  .teks-kanan {
    text-align: right;
  }

  .gplay-mobile {
    /*position: absolute;
      right: 0;*/
  }

  .card-panel {
    top: -48px;
    min-height: unset !important;
    /*padding-left: 10px;
      padding-right: 10px;*/
  }

  .slider-info,
  .slider-info>img {
    width: 240px;
    height: 200px;
  }

  .slider-info>img {
    width: 250px;
    height: 100px;
  }

  .slider-info .judul,
  .slider-info .isi {
    text-align: justify;
    width: 240px;
  }

  /*.slider-beranda{
      height: 280px;
    }*/
  .cari-atas {
    width: 130px;
  }

  .menu-utama {
    width: 50%;
    margin-top: 50px;
    height: 100%;
  }

  .ant-menu-sub.ant-menu-inline {
    background: transparent;
    color: #000;
  }

  .ant-menu-item>a {
    color: #000 !Important;
  }

  .ant-menu-sub>.ant-menu-item>a {
    color: #000 !Important;
  }

  .single-content {
    margin-top: 60px;
    /*margin-left: 30px;
      margin-right: 20px;*/
  }

  .info-ticker {
    display: none;
  }

  .blok-gap-ganjil,
  .blok-gap-genap,
  .blok-gap-netral {
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0px;
    padding-bottom: 15px;
    border-radius: 10px;
  }

  .awssld__bullets {
    /*bottom: 180px !Important;*/
    bottom: 115px !Important;
    z-index: 10000 !important;
    /*border:  1px solid red !Important;*/
  }

  .slider-beranda {
    margin-top: 50px;
    height: 220px;
  }

  .slider-detail {
    bottom: 0 !important;
  }

  /* .slider-detail .awssld__content>img {
    height: 245px !Important;
    object-fit: fill;
  } */

  .awssld__bullets>button {
    width: 5px !Important;
    height: 5px !Important;
    background: #fff;
    /*background: #000 !Important;*/
  }

  .single-content {
    /*margin:  60px;*/
    margin: 60px auto;
    width: 95%;
  }

  .panel-info-ticker {
    display: none;
  }

  .not-avaliable-usaha {
    border-radius: 0px;
    padding: 10px;
    font-size: 18px;
    /*color: #fff;
      text-transform: lowercase;
      font-weight: bold;*/
  }

  .not-avaliable-produk {
    padding: 10px;
    font-size: 12px;
  }

}


/* For Tablets */
@media screen and (min-width: 540px) and (max-width: 768px) {

  /* .row-info{
    position: unset;
  } */
  .titik-dua::after {
    content: unset;
  }

  .row-info .btn-selengkapnya {
    position: unset !important;
  }

  .slider-info {
    width: 450px;
    height: 400px;
  }

  .slider-info>img {
    width: 450px;
    height: 300px;
  }

  .slider-info .judul,
  .slider-info .isi {
    text-align: justify;
    width: 450px;
  }

  .slider-beranda {
    height: 380px;
  }

  .cari-atas {
    width: 180px;
  }

  .menu-utama {
    width: 50%;
    margin-top: 50px;
    height: 100%;
  }

  .ant-menu-sub.ant-menu-inline {
    background: transparent;
    color: #fff;
  }

  .ant-menu-sub>.ant-menu-item>a {
    color: #fff !Important;
  }

  /*.menu-utama-mobile{
      background: linear-gradient(to right, rgb(106, 113, 252, 0.3) , rgb(29, 198, 244, 0.8)) !Important;
    }*/
  .single-content {
    margin-top: 60px;
    /*margin-left: 30px;
      margin-right: 20px;*/
  }
}

.detail-page {
  margin-top: 120px;
  padding-left: 30px;
  padding-right: 30px;
}

.item-gbr-list {
  display: inline;
}

.title-ulasan-label {
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
  /* border-top: 1px solid #ccc; */
}

.item-gbr-list img {
  height: 60px;
  margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
}

/* For pc */
@media screen and (min-width: 768px) {
  .card-panel {
    padding-top: unset !Important;
    border-top-left-radius: 0px !Important;
    border-top-right-radius: 0px !Important;
    top: unset !Important;
    width: 95%;
    margin: 0px auto;
  }

  .slider-info {
    width: 450px;
    height: 400px;
  }

  .slider-info>img {
    width: 450px;
    height: 300px;
  }

  .slider-info .judul,
  .slider-info .isi {
    text-align: justify;
    width: 480px;
  }

  .slider-beranda {
    width: 95%;

    /*height: 300px;*/

    margin: 0px auto;
    margin-top: 120px;

    border-radius: 10px !Important;

    height: 500px !Important;
  }

  .awssld__content,
  .awssld__box {
    position: unset !Important;
  }

  /* .awssld__content>img {
    border-radius: 10px;
    height: 500px !Important;
    object-fit: fill !Important;
  } */

 

  /*.slider-detail  .awssld__container{

      background-color: linear-gradient(to right, rgb(127,127,127, 0.3) , rgb(225,219,219, 0.3)) !Important;
    }*/
  /*.slider-detail  .awssld__content > img{
      background: linear-gradient(to right, #6a71fc , #1dc6f4) !Important;
      height: 450px !Important;      
      object-fit: scale-down !Important;
      object-position: all;
    }*/

  .awssld__bullets {
    bottom: 80px !Important;
    /*z-index: 9001 !important;*/
    /*border:  1px solid red !Important;*/
  }

  .awssld__bullets>button {
    width: 10px !Important;
    height: 10px !Important;
    background: #fff;
    /*background: #000 !Important;*/
  }

  .single-content {
    /*margin:  60px;*/
    margin: 60px auto;
    width: 95%;
    margin-top: 120px;
  }

  .blok-gap-ganjil {
    background: linear-gradient(to right, rgb(127, 127, 127, 0.3), rgb(225, 219, 219, 0.3)) !Important;
  }

  .blok-gap-ganjil,
  .blok-gap-genap {
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0px;
    padding-bottom: 15px;
    border-radius: 10px;
  }

  .garis-panel {
    display: none;
  }

  .heading {
    /*background: linear-gradient(to right, #6a71fc , #1dc6f4) !Important;*/

  }

  .heading .tengah {
    font-weight: bold;
    text-align: center;
  }

  .ant-menu-item>a {
    color: #000 !Important;
  }

  .menu-akun {
    /*position: absolute !Important;
      right: 0;*/
    /*align-content: flex-end;
      justify-content: flex-end;*/
  }

  .menu-cari {
    margin-left: auto;
    /*position: absolute !Important;
      right: 50px;*/
    /*margin-left: auto !Important;*/
  }

  .available-on {
    margin-top: 10px;
  }

  .teks-download {
    padding-bottom: 30px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #4B4B4B;
  }

  .teks-kanan {
    text-align: right;
  }

  .panel-footer {
    margin-bottom: 30px;
  }

  .gplay-mobile {
    display: none;
  }

  .menu-utama {
    display: flex;
    align-items: center;
  }

  .loading-slider-atas h3 {
    height: 500px !Important;
  }

  .footernya {
    font-size: 15px;
    padding-top: 50px;
    padding-left: 200px;
    padding-right: 200px;
    color: #fff;
  }

  .footer-header {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .footer-atas {
    border-bottom: 1px solid #fff;
  }

  .footer-bawah {
    text-align: center;
    padding-top: 10px;
  }

}